import React from 'react';
import { Link } from 'react-router-dom';
import errorTest from 'Assets/errorTest.svg';

import 'Pages/Home/Components/Errorserver/styles.css';

const Errorserver = () => {
  return (
    <>
      <div className="errorTest">
        <h3>
          <h2>
            Vixi...<br></br>
          </h2>
          Houve um erro inesperado, tente novamente em alguns instantes...
        </h3>
        <div>
          <Link to={`/`}>
            <button>Voltar a tela Inicial</button>
          </Link>
        </div>
        <img src={errorTest} alt="" />
      </div>
    </>
  );
};

export default Errorserver;
