import React from 'react';
import logoGloboImg from 'Assets/logoGloboImg.png';
import FacebookLogo from 'Assets/FacebookLogo.svg';
import InstagramLogo from 'Assets/InstagramLogo.svg';
import TwitterLogo from 'Assets/TwitterLogo.svg';
import LinkedinLogo from 'Assets/LinkedinLogo.svg';
import houseLineIcon from 'Assets/houseLineIcon.svg';
import phoneIcon from 'Assets/phoneIcon.png';
import handshakeIcon from 'Assets/handshakeIcon.svg';
import 'Components/Footer/style.css';
function Footer() {
  let date = new Date().getFullYear();

  return (
    <section>
      <footer id="Footer">
        <div className="containerFooter">
          <div className="logoFooter">
            <a href="#" target="_blank">
              <img src={logoGloboImg} alt="Logo Globo Cereais" />
            </a>
            <p>
              Siga a Globo Cereais<br></br>nas redes sociais
            </p>
          </div>

          <div className="containerSocial">
            <div className="boxSocialMedia ">
              <div className="barFooter "></div>
              <a
                href="https://www.instagram.com/globocereais_oficial/"
                target="_blank"
              >
                <img src={InstagramLogo} alt="Logo of Instagram" />
              </a>
            </div>

            <div className="boxSocialMedia ">
              <div className="barFooter "></div>
              <a
                href="https://www.linkedin.com/company/globo-cereais"
                target="_blank"
              >
                <img src={LinkedinLogo} alt="Logo of Linkedin" />
              </a>
            </div>

            <div className="boxSocialMedia ">
              <div className="barFooter "></div>
              <a
                href="https://web.facebook.com/globocereais/?_rdc=1&_rdr"
                target="_blank"
              >
                <img src={FacebookLogo} alt="Logo of Facebook" />
              </a>
            </div>
            <div className="boxSocialMedia ">
              <div className="barFooter "></div>
              <a href="https://twitter.com/globocereais" target="_blank">
                <img src={TwitterLogo} alt="Logo of Twitter" />
              </a>
            </div>
          </div>
        </div>
        <div className="infoFooter">
          <h4>
            <a href="https://www.globocereais.com/" target="_blank">
              <img src={houseLineIcon} alt="Icon of Enterprise" />
              <p>Conheça à Globo Cereais</p>
            </a>
          </h4>
          <h4>
            <a href="#" target="_blank">
              <img src={phoneIcon} alt="icon Information" />
              <p>Contato</p>
            </a>
          </h4>
          <h4>
            <a href="#" target="_blank">
              <img src={handshakeIcon} alt="icon Information" />
              <p>Colaboradores</p>
            </a>
          </h4>

          <div className="FooterMobile">
            <a href="https://pt-br.facebook.com/globocereais/" target="_blank">
              <img src={FacebookLogo} alt="Facebook Logo" />
              Facebook
            </a>
            <a href="https://twitter.com/globocereais" target="_blank">
              <img src={TwitterLogo} alt="Twitter Logo" />
              Twitter
            </a>
            <a
              href="https://www.instagram.com/globocereais_oficial/"
              target="_blank"
            >
              <img src={InstagramLogo} alt="Instagram Logo" />
              Instagram
            </a>
            <a
              href="https://www.linkedin.com/company/globo-cereais/"
              target="_blank"
            >
              <img src={LinkedinLogo} alt="Linkedln Logo" />
              Linkedln
            </a>
          </div>
          <h5>Copyright ©{date} Globo Cereais</h5>
        </div>
      </footer>
    </section>
  );
}

export default Footer;
