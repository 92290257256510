import React from 'react';
import notDataIcon from 'Assets/notDataIcon.svg';
import 'Pages/Home/Components/ErrorPage/styles.css';

const ErrorPage = () => {
  return (
    <>
      <section className="AlertVacanciesNotAvaible">
        <h1 className="StyleError">Ops..</h1>
        <h4>No momento não temos vagas disponíveis !</h4>
        <img src={notDataIcon} alt="" />
      </section>
    </>
  );
};
export default ErrorPage;
