import React from 'react';

import 'Pages/Home/Components/LoadingComponent/styles.css';

export default function Loanding() {
  return (
    <section className="loandingComponent">
      <h4>Carregando...</h4>
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
}
