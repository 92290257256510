import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Header, Footer } from 'Components';
import { Confirmation, Errorserver } from 'Pages/Home/Components';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import CardVacancie from 'Pages/Home/Components/VacantMenu/CardVacancie';

import {
  EMAIL_VALIDATION,
  MASK_PHONE,
  PHONE_VALIDATION,
} from 'Pages/utils/Validations';
import 'Pages/Home/Components/VacantMenu/style.css';

function VacanciesAll() {
  const { idvacancie } = useParams();
  const [vacancie, setVacancie] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const refInputAtachament = useRef(null);
  const [observation, setObservation] = useState('');
  const [formation, setFormation] = useState('');
  const [statusFormation, setStatusFormation] = useState('');
  const [course, setCourse] = useState('');
  const [institution, setInstitution] = useState('');
  const [language, setLanguage] = useState('');
  const [languageDegree, setLanguageDegree] = useState('');
  const [experience, setExperience] = useState('Sim');
  const [company, setCompany] = useState('');
  const [driverLicense, setDriverLicense] = useState('');
  const [responsability, setResponsability] = useState('');
  const [descriptionResponsability, setDescriptionResponsability] =
    useState('');

  const [sucess, setSucess] = useState(false);
  const [vacantData, setVacantData] = useState('');
  const [loading, setLoading] = useState(false);
  const [charging, setCharging] = useState(true);
  const [state, setState] = useState('');
  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, serListaCidades] = useState([]);

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    refInputAtachament: '',
  });

  function addError(field, message) {
    setErrors({ ...errors, [field]: message });
  }
  function delError(field) {
    let tmp = errors;
    delete tmp[field];
    setErrors({ ...tmp });
  }

  function handleValueName(name) {
    if (name.length < 3 || name.length > 40 || name.value === 'a') {
      addError('name', 'Nome deve conter entre 3 a 40 caracteres');
    } else {
      delError('name');
    }
    setName(name);
  }

  function handleEmail(email) {
    if (email.length < 6 || email.length > 64) {
      addError('email', 'E-mail deve conter 6 a 30 caracteres');
    } else if (!new RegExp(EMAIL_VALIDATION).test(email)) {
      addError('email', 'Este e-mail não é valido');
    } else {
      delError('email');
    }

    setEmail(email);
  }

  function handlePhone(phone) {
    phone = phone.replace(MASK_PHONE.br, '($1) $2-$3');
    if (phone.length < 8 || phone.length > 15) {
      addError('phone', 'Telefone não pode ser nulo');
    } else if (!new RegExp(PHONE_VALIDATION.br).test(phone)) {
      addError('phone', 'Telefone não pode ser nulo');
    } else {
      delError('phone');
    }
    setPhone(phone);
  }

  async function HandleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();

    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone.replace(/[^0-9,]*/g, ''));
    formData.append('city', city);
    formData.append('state', state);
    formData.append('atachment', refInputAtachament.current.files[0]);
    formData.append('observation', observation);
    formData.append('formation', formation);
    formData.append('statusFormation', statusFormation);
    formData.append('course', course);
    formData.append('institution', institution);
    formData.append('language', language);
    formData.append('languageDegree', languageDegree);
    formData.append('experience', experience);
    formData.append('company', company);
    formData.append('responsability', responsability);
    formData.append('descriptionResponsability', descriptionResponsability);
    formData.append('id_vacancies', idvacancie);
    formData.append('driverLicense', driverLicense);

    console.log(
      name,
      email,
      phone,
      city,
      state,
      refInputAtachament.current.files,
      observation,
      formation,
      statusFormation,
      course,
      institution,
      language,
      languageDegree,
      experience,
      company,
      responsability,
      descriptionResponsability,
      driverLicense,
    );

    fetch(`${process.env.REACT_APP_API}/user`, {
      method: 'POST',
      body: formData,
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          setVacantData(data);
          setSucess(true);
          setLoading(true);
        }),
      )

      .catch((err) => {
        <Errorserver />;
        console.log(err);
      });
  }

  async function handleState(e) {
    setState(e.target.value);
    const getCities = await fetch(
      `${process.env.REACT_APP_API}/cities/${e.target.options.selectedIndex}`,
    );
    const cities = await getCities.json();
    console.log(cities);
    serListaCidades(cities);
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/states`).then((res) => {
      res.json().then((responseData) => {
        console.log(responseData);
        setListaEstados(responseData);
      });
    });
  }, []);

  const customStyles = {
    control: (base) => ({
      ...base,
      width: '100%',
      maxWidth: '100%',
    }),
  };

  const handleOptionChange = (e) => {
    setExperience(e.target.value);
  };

  // if (!vacancie.id) {
  //   return <ComponentVacantMenu />;
  // }

  // Comentario importante não pode ser excluido por enquanto

  function formationCondition() {
    if (formation === 'Técnico' || formation === 'Superior') {
      return (
        <>
          <div className="ContLanguage ">
            <div className="courseContent">
              <label htmlFor="name">
                <p className="red">*</p> Curso
              </label>
              <input
                type="text"
                value={course}
                onChange={(e) => setCourse(e.target.value)}
              />
            </div>
            <div className="courseInstitution">
              <label htmlFor="name">
                <p className="red">*</p> Instituição
              </label>
              <input
                type="text"
                value={institution}
                onChange={(e) => setInstitution(e.target.value)}
              />
            </div>
          </div>
        </>
      );
    }
  }

  function formationExperience() {
    if (experience === 'Sim') {
      return (
        <>
          <label htmlFor="name">
            <p className="red">*</p> Empresa
          </label>
          <input
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
          />
          <label htmlFor="name">
            <p className="red">*</p> Cargo
          </label>
          <input
            type="text"
            value={responsability}
            onChange={(e) => setResponsability(e.target.value)}
            required
          />
        </>
      );
    }
  }

  return (
    <>
      <Header />

      <section className="containerVacantMenu">
        {sucess === true ? (
          <Confirmation vacancieData={vacantData} title={vacancie.title} />
        ) : (
          <>
            <CardVacancie />
            <div className="formCandidate">
              <form action="POST" onSubmit={HandleSubmit}>
                <div className="positionTitle">
                  <p className="positionContent">Candidate-se</p>
                  <div className="separatorBar"></div>
                </div>
                <fieldset>
                  <label htmlFor="name">
                    <p className="red">*</p> Nome
                  </label>

                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => handleValueName(e.target.value)}
                    placeholder="Digite seu nome"
                    required
                  />
                  {errors.name && (
                    <small className="ErrorStyle">{errors.name}</small>
                  )}
                </fieldset>
                <fieldset>
                  <label htmlFor="email">
                    <p className="red">*</p> E-mail
                  </label>

                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => handleEmail(e.target.value)}
                    placeholder="Digite seu email"
                    required
                  />
                  {errors.email && (
                    <small className="ErrorStyle">{errors.email}</small>
                  )}
                </fieldset>
                <fieldset>
                  <label htmlFor="telefone">
                    <p className="red">*</p> Telefone
                  </label>

                  <InputMask
                    type="tel"
                    mask="(99) 99999-9999"
                    maskChar=""
                    name="phone"
                    value={phone}
                    onChange={(e) => {
                      handlePhone(e.target.value);
                    }}
                    id="telefone"
                    placeholder="(00)00000-0000"
                    required
                  />
                  {errors.phone && (
                    <small className="ErrorStyle">{errors.phone}</small>
                  )}
                </fieldset>

                <div className="containerCandidate">
                  <div className="containerState">
                    <label htmlFor="estado">
                      <p className="red">*</p> Estado <br></br>
                    </label>

                    <select
                      name="state"
                      id="state"
                      value={state}
                      onChange={handleState}
                      required={true}
                    >
                      <option value="">-SELECIONE-</option>
                      {listaEstados.map((list) => {
                        return (
                          <option key={list.id} value={list.name}>
                            {list.uf}
                          </option>
                        );
                      })}
                    </select>
                    {errors.state && (
                      <small className="ErrorStyle">{errors.state}</small>
                    )}
                  </div>
                  <div className="lina">
                    <fieldset>
                      <label htmlFor="cidade">
                        <p className="red">*</p> Cidade
                      </label>

                      <Select
                        required={true}
                        defaultValue={city}
                        placeholder="Digite a cidade"
                        onChange={(value) => {
                          setCity(value.name);
                        }}
                        getOptionValue={(item) => item.name}
                        getOptionLabel={(item) => item.name}
                        options={listaCidades}
                        styles={customStyles}
                      />
                    </fieldset>
                  </div>
                </div>

                <details className="collapseContainer">
                  <summary className="titleCollapse">
                    <div>Sua formação</div>
                  </summary>

                  <div className="boxCollapse">
                    <div className="containerForm">
                      <div className="teste">
                        <div className="fieldSchoolFormation">
                          <label htmlFor="name">
                            <p className="red">*</p> Formação
                          </label>
                          <select
                            id="curso"
                            onChange={(e) => setFormation(e.target.value)}
                            name="select"
                            defaultValue={'DEFAULT'}
                            required={true}
                          >
                            <option value="DEFAULT" disabled>
                              -SELECIONE-
                            </option>
                            <option value="Fundamental">Fundamental</option>
                            <option value="Médio">Médio</option>
                            <option value="Técnico">Técnico</option>
                            <option value="Superior">Superior</option>
                          </select>

                          {errors.formation && (
                            <small className="ErrorStyle">
                              {errors.formation}
                            </small>
                          )}
                        </div>

                        <div className="fieldSchoolStatus">
                          <label htmlFor="name">
                            <p className="red">*</p> Status
                          </label>
                          <select
                            onChange={(e) => setStatusFormation(e.target.value)}
                            name="select"
                            defaultValue={'DEFAULT'}
                            required={true}
                          >
                            <option value="DEFAULT" disabled>
                              -SELECIONE-
                            </option>
                            <option value="Completo">Completo</option>
                            <option value="Andamento">Andamento</option>
                            <option value="Incompleto">Incompleto</option>
                          </select>
                          {errors.name && (
                            <small className="ErrorStyle">
                              {errors.formation}
                            </small>
                          )}
                        </div>
                      </div>
                      {formationCondition()}
                    </div>

                    <div className="ContLanguage">
                      <div className="sizeField">
                        <label htmlFor="name">
                          <p className="red">*</p> Idioma
                        </label>

                        <select
                          onChange={(e) => setLanguage(e.target.value)}
                          name="select"
                          defaultValue={'DEFAULT'}
                          required={true}
                        >
                          <option value="DEFAULT">-SELECIONE-</option>
                          <option value="Português">Português</option>
                          <option value="Inglês">Inglês</option>
                          <option value="Espanhol">Espanhol</option>
                          <option value="Francês">Francês</option>
                          <option value="Russo">Russo</option>
                        </select>
                      </div>
                      <div className="sizeField">
                        <label htmlFor="name">
                          <p className="red">*</p> Nível
                        </label>
                        <select
                          onChange={(e) => setLanguageDegree(e.target.value)}
                          name="select"
                          defaultValue={'DEFAULT'}
                          required={true}
                        >
                          <option value="DEFAULT">-SELECIONE-</option>
                          <option value="Basico">Basico</option>
                          <option value="Intermediario">Intermediario</option>
                          <option value="Avançado">Avanço</option>
                          <option value="Nativo/Fluente">Nativo/Fluente</option>
                        </select>
                      </div>
                    </div>
                    <label htmlFor="name">
                      <p className="red">*</p> Possui CNH?
                    </label>
                    <select
                      onChange={(e) => setDriverLicense(e.target.value)}
                      defaultValue={'DEFAULT'}
                      className="inputCnhStyle"
                    >
                      <option value="DEFAULT" disabled>
                        -SELECIONE-
                      </option>

                      <option value="A">A</option>
                      <option value="A/B">A/B</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="E">E</option>
                      <option value="Não possui">Não possuo CNH</option>
                    </select>
                  </div>
                </details>

                <details className="collapseContainer">
                  <summary className="titleCollapse" required>
                    Sua experiência
                  </summary>
                  <div className="boxCollapse">
                    <div className="contentCollapse">
                      <label htmlFor="name">
                        <p className="red">*</p>Você possui experiência
                        profissional para esta Vaga?
                      </label>
                      <div className="inputRadius">
                        <div className="inputYes">
                          <input
                            type="radio"
                            id="yesRadio"
                            name="optionExp"
                            value="Sim"
                            checked={experience === 'Sim'}
                            onChange={handleOptionChange}
                            required
                          />
                          <label htmlFor="yes">Sim</label>
                        </div>
                        <div className="inputYes">
                          <input
                            type="radio"
                            id="noRadio"
                            name="optionExp"
                            value="Não"
                            checked={experience === 'Não'}
                            onChange={handleOptionChange}
                            required
                          />
                          <label htmlFor="yes">Não</label>
                        </div>
                      </div>

                      {formationExperience()}
                      <div className="containerDescriptionResponsability">
                        <label htmlFor="name">
                          <p className="red">*</p>Descrição das atividades que
                          desempenhou neste Cargo ?
                        </label>
                        <textarea
                          cols="30"
                          rows="10"
                          value={descriptionResponsability}
                          onChange={(e) =>
                            setDescriptionResponsability(e.target.value)
                          }
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </details>

                <fieldset>
                  <label htmlFor="file">
                    <p className="red">*</p> Anexar Curriculo
                  </label>
                  <label htmlFor="file" className="fileStyle">
                    Arraste Arquivos em pdf 
                  </label>

                  <input
                    type="file"
                    className="file"
                    id="file"
                    ref={refInputAtachament}
                    name="file"
                    accept="application/pdf"
                    required
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="observation">Observação</label>
                  <textarea
                    name="observation"
                    value={observation}
                    onChange={(e) => setObservation(e.target.value)}
                    id="observation"
                    cols="30"
                    rows="10"
                    placeholder="  Deixe uma Sugestão"
                  ></textarea>
                </fieldset>
                <button type="submit" className="buttonSubmit">
                  {loading && (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  {loading && <span>Registrando</span>}
                  {!loading && (
                    <span className="positionIcon">
                      <p> Me candidatar </p>
                    </span>
                  )}
                </button>
              </form>
            </div>
          </>
        )}
      </section>

      <Footer />
    </>
  );
}

export default VacanciesAll;
