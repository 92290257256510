import { React } from 'react';
import { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { getSessionToken } from 'utils/sessionLocalStore';
import { BiArrowToLeft } from 'react-icons/bi';
import { AiFillEye } from 'react-icons/ai';
import ModalBoxVacancies from 'Pages/Home/Components/ModalBoxvacancies';
import Loanding from 'Pages/Home/Components/LoadingComponent';
import bottonIcon from 'Assets/bottonIcon.svg';
import calendarIcon from 'Assets/calendarIcon.svg';
import eyeIcon from 'Assets/eyeIcon.svg';
import 'Pages/Home/Components/ResultsCandidates/styles.css';

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');
myHeaders.append('Authorization', `Bearer ${getSessionToken()}`);

export default function Results() {
  const { idvacancie } = useParams();
  const { title } = useParams();
  const [candidate, setCandidate] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [candidateSelected, setCandidateSelected] = useState({});
  const [loandig, setLoandig] = useState(true);

  useEffect(() => {
    setLoandig(true);

    fetch(`${process.env.REACT_APP_API}/candidates/filter/${idvacancie}`, {
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then((results) => {
        if (results.length === 0) {
          alert('no momento não possui candidatos para essa vaga');
        }
        setCandidate(results);
        setLoandig(false);
      });
  }, []);

  const formatDate = (date) => {
    let createdAt = new Date(date);
    let formattedDate =
      createdAt.getDate() +
      '/' +
      (createdAt.getMonth() + 1) +
      '/' +
      createdAt.getFullYear();

    return formattedDate;
  };

  return (
    <>
      <section>
        <div className="listVacanciesCreate">
          {loandig ? (
            <Loanding />
          ) : (
            <>
              <div className="containerTitleResults">
                <h1 className="textListCandidate">Vaga: {title} </h1>
                <div className="separatorBar"></div>
              </div>
              <NavLink to={'/painel/filter'} className="buttonReturnBox">
                <button className="buttonReturn">
                  <BiArrowToLeft fontSize={20} />
                  Voltar para tela de vagas
                </button>
              </NavLink>

              <div className="boxList">
                <tr class="titleBoxlist">
                  <td>
                    <img src={bottonIcon} alt="" />
                    <p>Nome</p>
                  </td>
                  <td>
                    <img src={calendarIcon} alt="" />
                    <p>Data Cadastro</p>
                  </td>
                  <td>
                    <img src={eyeIcon} alt="" />
                    <p>Visualizar</p>
                  </td>
                </tr>
                <div className="viewContainer">
                  <div className="contentBoxs">
                    <>
                      <table className="tableVacancies">
                        {loandig ? (
                          <Loanding />
                        ) : (
                          <>
                            {isModalVisible ? (
                              <ModalBoxVacancies
                                candidate={candidateSelected}
                                onClose={() => {
                                  setIsModalVisible(false);
                                }}
                              />
                            ) : null}
                          </>
                        )}

                        {candidate.map((list) => {
                          return (
                            <div
                              class="boxContVacancie"
                              onClick={() => {
                                setIsModalVisible(true);
                                setCandidateSelected(list);
                              }}
                              key={list.id}
                            >
                              <div className="styleTitle">
                                <p>{list.name}</p>
                              </div>
                              <div className="styleDateCandidate">
                                {formatDate(list.created_at)}
                              </div>

                              <div className="styleButtonView">
                                <button className="bgpath">
                                  <AiFillEye
                                    className="AiFillEyeStyle"
                                    color="#304FFE"
                                    fontSize={20}
                                  />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </table>
                    </>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
