import React from 'react';
import { NavLink } from 'react-router-dom';
import LogoGlobo from 'Assets/LogoGlobo.png';
import 'Components/Header/style.css';

function Header() {
  return (
    <section>
      <div className="header">
        <header className="App-header">
          <NavLink to="/">
            <img id="logo" src={LogoGlobo} alt="Logo" />
          </NavLink>

          <nav className="App-nav">
            <p className="title-App-nav">Venha fazer parte do nosso time</p>
          </nav>
        </header>
      </div>
    </section>
  );
}

export default Header;
