import React, { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { MASK_PHONE } from 'Pages/utils/Validations';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { getSessionToken } from 'utils/sessionLocalStore';
import Loanding from 'Pages/Home/Components/LoadingComponent';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import clipIconWhite from 'Assets/clipIconWhite.svg';
import IconX from 'Assets/IconX.svg';
import 'Pages/Home/Components/ModalBoxvacancies/styles.css';

const ModalBoxvacancies = ({
  id = 'modal',
  onClose = () => {},
  candidate = {},
}) => {
  const urlToDownload = `${process.env.REACT_APP_API}/candidate/atachment/${candidate.id}`;
  const [pdfDefault] = useState(candidate.atachment);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;
  console.log(candidate.atachment);

  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  console.log(candidate);

  function formatPhone(phone) {
    phone = phone.replace(MASK_PHONE.br, '($1) $2-$3');
    return phone;
  }

  console.dir(defaultLayoutPluginInstance);

  return (
    <section>
      <div className="modalBoxIntern" onClick={handleOutsideClick}>
        <div className="viewBox">
          {pdfDefault && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.js">
              <div
                style={{
                  height: '750px',
                  width: '900px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Viewer
                  fileUrl={`${process.env.REACT_APP_API}/candidate/atachment/${candidate.id}`}
                  plugins={[defaultLayoutPluginInstance, getFilePluginInstance]}
                  httpHeaders={{ Authorization: `Bearer ${getSessionToken()}` }}
                  withCredentials={false}
                />
              </div>
            </Worker>
          )}
        </div>

        <div className="contentModal">
          <div className="btnExit">
            <button onClick={onClose}>
              <img src={IconX} alt="" />
            </button>
          </div>

          <div className="boxModal">
            <div className="positionModal">
              <div className="titleBoxModal">
                <h2>Informações Gerais sobre o candidato</h2>
              </div>

              <div className="positionInformation">
                <h3>Nome:</h3>
                <p>{candidate.name}</p>
              </div>
              <div className="positionInformation">
                <h3>E-mail:</h3>
                <p>{candidate.email}</p>
              </div>

              <div className="positionInformation">
                <h3>Telefone:</h3>
                <p>{formatPhone(candidate.phone)}</p>
              </div>
              <div className="positionInformation">
                <h3>Cidade:</h3>
                <p>{candidate.city}</p>
              </div>
              <div className="positionInformation">
                <h3>Formação:</h3>
                <p>
                  Ensino {candidate.formation} {candidate.statusFormation}
                </p>
              </div>
              <div className="positionInformation">
                <h3>Curso:</h3>
                <p>{candidate.course}</p>
              </div>
              <div className="positionInformation">
                <h3>Instituição de Ensino:</h3>
                <p>{candidate.institution}</p>
              </div>
              <div className="positionInformation">
                <h3>Idioma:</h3>
                <p>
                  {candidate.language} {candidate.languageDegree}
                </p>
              </div>

              <div className="positionInformation">
                <h3>Candidato possui experiência profissional:</h3>
                <p>{candidate.experience}</p>
              </div>

              <div className="positionInformation">
                <h3>Empresa:</h3>
                <p>{candidate.company}</p>
              </div>

              <div className="positionInformation">
                <h3>Cargo:</h3>
                <p>{candidate.experience}</p>
              </div>
              <div className="positionInformation">
                <h3>CNH:</h3>
                <p>{candidate.driverLicense}</p>
              </div>
              <h3>Descrição de Atividades:</h3>
              <div className="positionInformation boxDescriptionResponsability">
                <p>{candidate.descriptionResponsability}</p>
              </div>

              <div className="note">
                <h3>Observação:</h3>
                <p>{candidate.observation}</p>
              </div>
            </div>
          </div>
          <div className="buttonModal">
            <div>
              <Download>
                {(props) => (
                  <button className="pullAttachment" onClick={props.onClick}>
                    <img
                      id="clipIconWhite"
                      src={clipIconWhite}
                      alt="Clip Icon"
                    />
                    <h3 className="spaceContent">Baixar Anexo</h3>
                  </button>
                )}
              </Download>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ModalBoxvacancies;
