import React from 'react';
import { Link } from 'react-router-dom';
import logoSmallGloboCereais from 'Assets/logoSmallGloboCereais.svg';

import 'Pages/Home/Components/Confirmation/styles.css';

const Confirmation = ({ vacancieData, title }) => {
  function currentDate() {
    var date = new Date();
    var day = String(date.getDate()).padStart(2, '0');
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var year = date.getFullYear();
    currentDate = day + '/' + month + '/' + year;
  }
  currentDate();

  return (
    <>
      <section className="boxConfirmation">
        {console.log(vacancieData)}
        <div className="modalSummary">
          <header className="headerConfirmation">
            <div className="logoConfirmation">
              <img src={logoSmallGloboCereais} alt="logo brand globo cereais" />
            </div>
          </header>

          <div className="containerConfirmation">
            <div className="positionTitle titleDone">
              <p> Você se Cadastrou com Sucesso !! </p>
              <div className="separatorBar"></div>
            </div>
          </div>

          <div className="resumeDone">
            <p>
              Olá <strong>{vacancieData.name} </strong>você se Cadastrou para a
              Vaga de
              <strong> {title} </strong>
              <br></br>
              no dia <strong>{currentDate}</strong>. ficamos felizes que você
              tenha interesse em trabalhar com a gente !! Fique de olho no
              e-mail email e telefone cadastrados para ficar por dentro das
              proximas etapas. <br></br>
              <strong>Boa sorte {vacancieData.name}</strong> estamos torcendo
              por você !!!
            </p>
          </div>

          <Link to={`/`}>
            <button className="buttonDone">Voltar a tela Inicial</button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Confirmation;
