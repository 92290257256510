import React, { useState } from 'react';
import { setSessionToken } from 'utils/sessionLocalStore';
import { EMAIL_VALIDATION } from 'Pages/utils/Validations';
import { FaArrowRight } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import globoLogo from 'Assets/globoLogo.svg';
import 'Pages/Home/Components/Login/styles.css';

var myHeaders = new Headers();

myHeaders.append('Content-Type', 'application/json');

const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [errors, setErrors] = useState({
    email: '',
    senha: '',
    errorLogin: '',
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function addError(field, message) {
    setErrors({ ...errors, [field]: message });
  }
  function delError(field) {
    let tmp = errors;
    delete tmp[field];
    setErrors({ ...tmp });
  }

  function handleEmail(email) {
    if (email.length < 6 || email.length > 64) {
      addError('email', 'E-mail deve conter 6 a 30 caracteres');
    } else if (!new RegExp(EMAIL_VALIDATION).test(email)) {
      addError('email', 'Este e-mail não é valido');
    } else {
      delError('email');
    }

    setEmail(email);
  }

  function handlePassword(senha) {
    if (senha.length < 6 || senha.length > 32) {
      addError('senha', 'senha deve conter 6 a 20 caracteres');
    } else {
      delError('senha');
    }

    setSenha(senha);
  }

  async function handleValidation(event) {
    event.preventDefault();
    setLoading(true);
    const checkLogin = await fetch(`${process.env.REACT_APP_API}/login/admin`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email,
        senha,
      }),
    });

    if (checkLogin.status === 200) {
      const responseData = await checkLogin.json();
      console.log(responseData);
      setSessionToken(responseData.token);
      history.push('/painel/filter');
    } else {
      setLoading(false);
      addError('errorLogin', 'Usuário ou senha inválidos');
    }
  }

  return (
    <>
      <section className="containerLogin">
        <div className="boxLogin">
          <div className="loginLogo animationLogo">
            <img src={globoLogo} alt="Logo globo Cerais" />
            <p className="loginTitle">Globo Cereais vagas</p>
          </div>
          <div className="containerLoginEnter">
            <h2>Entrar</h2>

            <form action="POST">
              <div className="styleLabelLogin ">
                <div>
                  <label>
                    <p className="starRed">*</p> E-mail
                  </label>
                  <input
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => handleEmail(e.target.value)}
                    required
                  />
                  {errors.email && (
                    <small className="ErrorStyle">{errors.email}</small>
                  )}
                </div>

                <div>
                  <label>
                    <p className="starRed">*</p>Senha
                  </label>

                  <input
                    type="password"
                    value={senha}
                    autoComplete="current-password"
                    autoCapitalize="false"
                    autoCorrect="false"
                    onChange={(e) => handlePassword(e.target.value)}
                    className="posLabel"
                    required
                  />
                  {errors.senha && (
                    <small className="ErrorStyle">{errors.senha}</small>
                  )}
                </div>
                <div className="errorLoginBox">
                  <small className="ErrorStyleAlertLogin ">
                    {errors.errorLogin}
                  </small>
                </div>

                <div>
                  <button
                    className="buttonSubmitLogin"
                    onClick={handleValidation}
                    disabled={loading}
                  >
                    {loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: '10px' }}
                      />
                    )}
                    {loading && <span>Entrando</span>}
                    {!loading && (
                      <span className="positionIcon">
                        <p>Entrar </p> <FaArrowRight />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
