import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Home from 'Pages/Home';
import VacantMenu from 'Pages/Home/Components/VacantMenu';

import {
  Alerts,
  BoxVacancies,
  Confirmation,
  Dashboard,
  ErrorPage,
  Errorserver,
  Loanding,
  Login,
} from 'Pages/Home/Components';
import ModalBoxvacancies from 'Pages/Home/Components/ModalBoxvacancies';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Home} path="/" exact />
        <Route component={VacantMenu} path="/vacantMenu/:idvacancie" exact />
        <Route component={BoxVacancies} path="/boxvacancies" exact />
        <Route component={ModalBoxvacancies} path="/boxmodal" exact />
        <Route component={Confirmation} path="/confirmation" exact />
        <Route component={ErrorPage} path="/errorPage" exact />
        <Route component={Errorserver} path="/errorserver" exact />
        <Route component={Alerts} path="/alerts" exact />
        <Route component={Login} path="/login" exact />
        <Route component={Dashboard} path="/painel" />
        <Route component={Loanding} path="/loanding" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
