import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ComponentVacantMenu from 'Pages/Home/Components/ComponentSkeleton/ComponentVacantMenu';

function CardVacancie() {
  useEffect(() => {
    setCharging(true);

    const timer = setTimeout(() => {
      fetch(`${process.env.REACT_APP_API}/vacancie/${idvacancie}`)
        .then((res) => res.json())
        .then((results) => {
          setVacancie(results);
          setCharging(false);
        });
    });
    return () => clearTimeout(timer);
  }, []);

  const [vacancie, setVacancie] = useState({});
  const [charging, setCharging] = useState(true);
  const { idvacancie } = useParams();

  return (
    <section className="positionAbout">
      {charging ? (
        <ComponentVacantMenu />
      ) : (
        <div className="containerJobs">
          <div className="positionTitle">
            <p className="titleSize">{vacancie.title}</p>
            <div className="separatorBar"></div>
          </div>

          <div className="aboutVacancies">
            <p>{vacancie.description}</p>
          </div>

          <div className="responsibility">
            <h4>Responsabilidades e Atribuições</h4>
            <ul className="positionQualification">
              <li>{vacancie.responsabilitys[0]}</li>
              <li>{vacancie.responsabilitys[1]}</li>
              <li>{vacancie.responsabilitys[2]}</li>
            </ul>
          </div>
          <div className="requirements">
            <h4>Requisitos e Qualificações</h4>
            <ul className="positionQualification">
              <li>{vacancie.requiriments[0]}</li>
              <li>{vacancie.requiriments[1]}</li>
              <li>{vacancie.requiriments[2]}</li>
            </ul>
          </div>
        </div>
      )}
    </section>
  );
}
export default CardVacancie;
