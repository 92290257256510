import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

export default function ComponentVacantMenu(props) {
  return (
    <Grid container wrap="nowrap">
      <Box sx={{ width: 650, my: 5 }}>
        <Skeleton variant="rectangular" width={650} height={600} />

        <Box sx={{ pr: 2 }}>
          <Typography gutterBottom variant="body2"></Typography>
          <Typography
            display="block"
            variant="caption"
            color="text.secondary"
          ></Typography>
          <Typography variant="caption" color="text.secondary"></Typography>
        </Box>
      </Box>
    </Grid>
  );
}
