import React, { useState, useEffect } from 'react';
import ModalBoxVacancies from '../ModalBoxvacancies';
import archiveBoxIconGreen from 'Assets/archiveBoxIconGreen.svg';
import { getSessionToken } from 'utils/sessionLocalStore';
import Loanding from 'Pages/Home/Components/LoadingComponent';
import 'Pages/Home/Components/BoxVacancies/styles.css';

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');
myHeaders.append('Authorization', `Bearer ${getSessionToken()}`);

const BoxVacancies = () => {
  const [filterState, setFilterState] = useState('Disponivel');
  const [candidatesList, setCandidatesList] = useState([]);
  const [candidateSelected, setCandidateSelected] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loandig, setLoandig] = useState(true);
  const [isFilterVisible, setIsFilterVisible] = useState('');

  useEffect(() => {
    setLoandig(true);

    fetch(`${process.env.REACT_APP_API}/users?status=${filterState}`, {
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then((results) => {
        console.log(results);
        setCandidatesList(results);
        setLoandig(false);
      });
  }, [filterState]);

  const formatDate = (date) => {
    let createdAt = new Date(date);
    let formattedDate =
      createdAt.getDate() +
      '/' +
      (createdAt.getMonth() + 1) +
      '/' +
      createdAt.getFullYear();

    return formattedDate;
  };

  const typeFilters = [
    { name: 'Todas', enabled: false },
    { name: 'Disponivel ', enabled: true },
    { name: 'Encerrado', enabled: false },
  ];

  return (
    <>
      <div className="containerBoxVac">
        <header className="headerBox ">
          <img
            src={archiveBoxIconGreen}
            className="logoBoxEmail"
            alt="Icon chat"
          />
          <div className="titleHeaderBox">
            <h2>Vagas</h2>
            <h3>Processo seletivo histórico</h3>
          </div>
        </header>
        {loandig ? (
          <Loanding />
        ) : (
          <>
            <section className="grid">
              <div className="menuBar">
                {typeFilters.map((filter) => {
                  return (
                    <button
                      id={filterState === filter.name && 'btnfixed'}
                      onClick={() => {
                        setFilterState(filter.name);
                      }}
                      key={filter.name}
                    >
                      {filter.name}
                    </button>
                  );
                })}

                <button onClick={() => setIsFilterVisible(true)}></button>
              </div>
              {isModalVisible ? (
                <ModalBoxVacancies
                  candidate={candidateSelected}
                  onClose={() => {
                    setIsModalVisible(false);
                  }}
                />
              ) : null}

              {candidatesList.map((candidate) => {
                return (
                  <button
                    className="btnStyleModal"
                    onClick={() => {
                      setIsModalVisible(true);
                      setCandidateSelected(candidate);
                    }}
                    key={candidate.id}
                  >
                    <div className="boxContainervacancies">
                      <div className="positionBox">
                        <div className="containerVacancies titleVacancies">
                          <h2>{candidate.title}</h2>
                          <p className="dataFormater">
                            {formatDate(candidate.created_at)}
                          </p>
                        </div>

                        <div className="boxTimeDate titleEmail">
                          <p>{candidate.email}</p>
                        </div>
                      </div>
                    </div>
                  </button>
                );
              })}
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default BoxVacancies;
