import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorPage } from '..';
import ComponentSkeleton from 'Pages/Home/Components/ComponentSkeleton/ComponentSkeleton';
import 'Pages/Home/Components/Vacancies/style.css';
import drawBgImg from 'Assets/drawBgImg.svg';

function Vacancies() {
  const [Vacancieslist, setVacancies] = useState([]);
  const [availabilityVacancies, setAvailabilityVacancies] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      fetch(`${process.env.REACT_APP_API}/vacancies`)
        .then((res) => res.json())
        .then((results) => {
          setVacancies(results);
          if (results.length == 0) {
            setAvailabilityVacancies(true);
          }
          setLoading(false);
        });
    });

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className="containerVacanciesBox">
        <div className="missionBox aboutCompany">
          <div className="boxTextMission">
            <div className="containerTitle">
              <p>Trabalhe Conosco</p>
              <div className="separatorBar"></div>
            </div>
            <div className="styleTextMission styleAbout">
              <p>
                A <strong>GLOBO CEREAIS</strong> busca a todo instante,
                oportunidades de negociação que o agronegócio mundial nos
                proporciona, disponibilizando-as com agilidade e rapidez aos
                nossos clientes. Não que isso seja novo e/ou inovador, porém com
                novas metodologias e canalizando energia na medida certa,
                provendo o que realmente importa e que faz diferença para a
                lucratividade sólida e saudável aos nossos parceiros.
              </p>

              <p>
                Com esse norteamento e com profissionais de conhecimento
                comprovado que dispomos, estamos prontos para atendê-los,
                forjando uma parceria de sucesso no mercado de agronegócio.
              </p>
              <p>
                Nossa missão pela busca contínua de oportunidades comerciais,
                nos remete ao vosso encontro, e assim, nos colocamos à
                disposição para contribuir com muito trabalho em seu dia a dia
                de atividades comerciais.
              </p>

              <p>
                <strong>
                  Por isso Convidamos Você para fazer Parte do nosso Time.
                </strong>
              </p>
            </div>
          </div>
          <div className="imageCollaborator">
            <img src={drawBgImg} alt="colaborador" />
          </div>
        </div>
      </section>
      <section className="grid">
        {availabilityVacancies == true ? (
          <ErrorPage />
        ) : (
          <>
            <div className="positionCenter">
              <div className="containerTitle">
                <p className="titleVacancies">Confira nossas Oportunidades</p>
                <div className="separatorBar"></div>
              </div>

              {loading ? (
                <ComponentSkeleton />
              ) : (
                <div className="vacancieBox">
                  {Vacancieslist.map((vacancie, index) => {
                    return (
                      <React.Fragment key={vacancie.id}>
                        <div className="vacanciesContent">
                          <div className="vacancie">
                            <h4>{vacancie.title}</h4>
                            <p className="heightBox">{vacancie.description}</p>
                            <div>
                              <a href="#">{vacancie.Link}</a>
                            </div>

                            <Link to={`/VacantMenu/${vacancie.id}`}>
                              <button className="buttonApplyVacancie">
                                Quero mer candidatar
                              </button>
                            </Link>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </section>
    </>
  );
}

export default Vacancies;
