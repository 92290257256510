import { React } from 'react';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import { IoEyeSharp } from 'react-icons/io5';
import { ImFolderOpen } from 'react-icons/im';
import { BsPencilSquare } from 'react-icons/bs';
import { BsInboxFill } from 'react-icons/bs';
import { FaUserAlt } from 'react-icons/fa';
import { Registration, ViewerBox, Perfil, Filter, ResultsCandidates } from '..';
import globoLogo from 'Assets/globoLogo.svg';
import squareLeftIcon from 'Assets/squareLeftIcon.svg';
import BoxVacancies from 'Pages/Home/Components/BoxVacancies';
import './styles.css';

const Dashboard = () => {
  function tamanho() {
    return console.log(Link.to);
  }
  return (
    <>
      <section className="dashContainer">
        <div className="dashboardContent">
          <div className="logoDashboard">
            <img src={globoLogo} alt="" />
          </div>

          <nav className="menuDashboard">
            <ul>
              <li title="usuario">
                <NavLink
                  to="/painel/perfil"
                  activeClassName="isActive"
                  onClick={tamanho}
                >
                  <FaUserAlt fontSize={25} />
                  <p>Usuário</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/painel/registration" activeClassName="isActive">
                  <BsPencilSquare fontSize={25} />

                  <p>Cadastrar Vaga</p>
                </NavLink>
              </li>

              <li>
                <NavLink to="/painel/filter" activeClassName="isActive">
                  <BsInboxFill fontSize={25} />
                  <p>Caixa de entrada</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/painel/ViewerBox" activeClassName="isActive">
                  <IoEyeSharp fontSize={25} />
                  <p>Visualizar Vagas</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/painel/boxvacancies" activeClassName="isActive">
                  <ImFolderOpen fontSize={25} />

                  <p>Histórico</p>
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="outDashbord">
            <div className="outCenter">
              <NavLink to={'/login'}>
                <img src={squareLeftIcon} alt="" />
                Sair
              </NavLink>
            </div>
          </div>
        </div>

        <div className="containerRoutes">
          <Switch>
            <Route component={Registration} path="/painel/registration" />
            <Route component={ViewerBox} path="/painel/ViewerBox" />
            <Route component={BoxVacancies} path="/painel/boxvacancies" />
            <Route component={Perfil} path="/painel/perfil" />
            <Route component={Filter} path="/painel/filter" />
            <Route
              component={ResultsCandidates}
              path="/painel/results/:idvacancie/:title"
            />
          </Switch>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
