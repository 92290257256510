import React, { Component } from 'react';
import { Vacancies } from 'Pages/Home/Components';
import { Footer, Header } from 'Components';

export default class Home extends Component {
  render() {
    return (
      <>
        <Header />
        <Vacancies />
        <Footer />
      </>
    );
  }
}
