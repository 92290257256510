import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

export default function ComponentSkeleton(props) {
  return (
    <Grid container wrap="nowrap">
      <Box sx={{ width: 201.6, marginRight: 5, my: 5 }}>
        <Skeleton variant="rectangular" width={201.6} height={170} />

        <Box sx={{ pr: 2 }}>
          <Typography gutterBottom variant="body2"></Typography>
          <Typography
            display="block"
            variant="caption"
            color="text.secondary"
          ></Typography>
          <Typography variant="caption" color="text.secondary"></Typography>
        </Box>

        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="100%" />
        </Box>
      </Box>

      <Box sx={{ width: 201.6, marginRight: 5, my: 5 }}>
        <Skeleton variant="rectangular" width={210} height={170} />

        <Box sx={{ pr: 2 }}>
          <Typography gutterBottom variant="body2"></Typography>
          <Typography
            display="block"
            variant="caption"
            color="text.secondary"
          ></Typography>
          <Typography variant="caption" color="text.secondary"></Typography>
        </Box>

        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="100%" />
        </Box>
      </Box>

      <Box sx={{ width: 201.6, marginRight: 5, my: 5 }}>
        <Skeleton variant="rectangular" width={210} height={170} />

        <Box sx={{ pr: 2 }}>
          <Typography gutterBottom variant="body2"></Typography>
          <Typography
            display="block"
            variant="caption"
            color="text.secondary"
          ></Typography>
          <Typography variant="caption" color="text.secondary"></Typography>
        </Box>

        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="100%" />
        </Box>
      </Box>

      <Box sx={{ width: 201.6, marginRight: 5, my: 5 }}>
        <Skeleton variant="rectangular" width={210} height={170} />

        <Box sx={{ pr: 2 }}>
          <Typography gutterBottom variant="body2"></Typography>
          <Typography
            display="block"
            variant="caption"
            color="text.secondary"
          ></Typography>
          <Typography variant="caption" color="text.secondary"></Typography>
        </Box>

        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="100%" />
        </Box>
      </Box>
    </Grid>
  );
}
