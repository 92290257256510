import React from 'react';
import { FcApproval } from 'react-icons/fc';
import 'Pages/Home/Components/Alerts/styles.css';

const Alerts = () => {
  return (
    <section className="boxAlerts">
      <FcApproval size={80}></FcApproval>
      <p>Usuário criado com sucesso</p>
    </section>
  );
};
export default Alerts;
