import React from 'react';
import { useEffect, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { FaArchive } from 'react-icons/fa';
import { FormDialog } from 'Pages/Home/Components';
import Loanding from 'Pages/Home/Components/LoadingComponent';
import bottonIcon from 'Assets/bottonIcon.svg';
import calendarIcon from 'Assets/calendarIcon.svg';
import creditIcon from 'Assets/creditIcon.svg';
import 'Pages/Home/Components/ViewerBox/styles.css';

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

const ViewerBox = (props) => {
  const [listBox, setListBox] = useState([]);
  const [loandig, setLoandig] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalDados, setModalDados] = useState({});

  const handleclick = (item) => {
    setModalDados(item);
    setOpen(true);
  };

  function handleSave(item) {
    const temp = [...listBox];
    temp[
      listBox.findIndex((vacancie) => {
        return vacancie.id === item.id;
      })
    ] = item;
    console.log(temp);
    setListBox(temp);
  }

  function handleDelete(item) {
    setListBox(
      listBox.filter((vacancie) => {
        return vacancie.id !== item.id;
      }),
    );
  }

  useEffect(() => {
    setLoandig(true);

    fetch(`${process.env.REACT_APP_API}/vacancies`)
      .then((res) => res.json())
      .then((results) => {
        setListBox(results);
        setLoandig(false);
      })
      .catch(function (error) {
        document.write(
          'Houve um erro inesperado. Tente novamente em alguns segundos...',
        );
      });
  }, []);

  const formatDate = (date) => {
    let created_date = new Date(date);
    let formattedDate =
      created_date.getDate() +
      '/' +
      (created_date.getMonth() + 1) +
      '/' +
      created_date.getFullYear();

    return formattedDate;
  };

  return (
    <>
      <section className="">
        <div className="listVacanciesCreate">
          <div className="containerTitleView">
            <h1 className="textSizeView">Vagas cadastradas</h1>
            <div className="separatorBarView"></div>
          </div>

          {loandig ? (
            <Loanding />
          ) : (
            <>
              <div className="boxList">
                <table className="titleboxlist">
                  <tbody>
                    <tr className="contentBoxList">
                      <td>
                        <img src={bottonIcon} alt="" />
                        <p>Nome Vaga</p>
                      </td>
                      <td>
                        <img src={calendarIcon} alt="" />
                        <p>Data Criação</p>
                      </td>
                      <td>
                        <img src={creditIcon} alt="" />
                        <p>Situação</p>
                      </td>
                      <td>
                        <img src={creditIcon} alt="" />
                        <p>Ação</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="viewContainer">
                  <div className="contentBoxView">
                    {listBox.map((value, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className="tableVacancies"
                            onClick={() => handleclick(value)}
                          >
                            <div className="boxContVacancies">
                              <div className="styleTitle">
                                <p>{value.title}</p>
                              </div>
                              <div className="styleDate">
                                {formatDate(value.created_date)}
                              </div>
                              <div className="styleDate">Ativa</div>

                              <div className="styleButtonEdit">
                                <button
                                  className="bgpath"
                                  onClick={() => handleclick(value)}
                                >
                                  <FaPencilAlt
                                    className="pencilicon"
                                    color="#304FFE"
                                  />
                                </button>
                                <button className="bgpath">
                                  <FaArchive
                                    className="pencilicon"
                                    color="#304FFE"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <FormDialog
          open={open}
          setOpen={setOpen}
          data={modalDados}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      </section>
    </>
  );
};

export default ViewerBox;
