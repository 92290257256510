import React from 'react';
import { Link } from 'react-router-dom';
import sucessIcon from 'Assets/sucessIcon.svg';

import './styles.css';

const SuccessRegistration = () => {
  return (
    <>
      <section className="boxSuccessRegistration">
        <Link to={'/'}>
          <button>Ir para vagas</button>
        </Link>

        <h1>Vaga cadastrada com sucesso !!!</h1>
        <img src={sucessIcon} alt="successIcon" />
      </section>
    </>
  );
};

export default SuccessRegistration;
