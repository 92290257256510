import React, { Component, lazy, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ImProfile } from 'react-icons/im';
import Loanding from 'Pages/Home/Components/LoadingComponent';
import 'Pages/Home/Components/Filter/style.css';
export default function Filter() {
  const { vacancies } = useParams();
  const { title } = useParams();
  const [listVacancies, setListVacancies] = useState([]);
  const [loandig, setLoandig] = useState(true);
  const [search, setSearch] = useState('');
  const [singleVacancies, setSingleVacancies] = useState({});

  useEffect(() => {
    setLoandig(true);

    fetch(`${process.env.REACT_APP_API}/vacancies`)
      .then((res) => res.json())
      .then((list) => {
        setListVacancies(list);
        setLoandig(false);
      });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/vacancie/${vacancies}/${title}`)
      .then((res) => res.json())
      .then((results) => {
        setSingleVacancies(results);
      });
  }, []);

  const filterVacancies = listVacancies.filter(
    (vacancie) =>
      String(vacancie.title)
        .toLocaleLowerCase()
        .indexOf(String(search).toLocaleLowerCase()) !== -1,
  );

  return (
    <>
      <section className="filterContainer">
        <span className="inputVacancies">
          <label htmlFor="search">Pesquisar por vagas</label>
          <input
            className="InputSearch"
            id="search"
            value={search}
            type="text"
            placeholder="ex: Analista de dados"
            onChange={(e) => setSearch(e.target.value)}
          />
        </span>
        {loandig ? (
          <Loanding />
        ) : (
          <>
            <div className="filterBox">
              {filterVacancies.map((vacancies, index) => {
                return (
                  <NavLink
                    key={vacancies.id}
                    to={`/painel/results/${vacancies.id}/${vacancies.title}`}
                    activeClassName="isActive"
                  >
                    <div key={index} className="filterCard">
                      <div className="boxIconCard">
                        <ImProfile color="#73E02E" fontSize={32} />
                      </div>
                      <h4>{vacancies.title}</h4>
                      <p> {vacancies.totalCandidates} Candidatos</p>
                      <small>Clique no card para visualizar candidatos</small>
                    </div>
                  </NavLink>
                );
              })}
            </div>
          </>
        )}
      </section>
    </>
  );
}
