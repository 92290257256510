import React, { useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { SuccessRegistration } from '..';
import 'Pages/Home/Components/Registration/style.css';

function Registration() {
  const [requiriments, setrequiriments] = useState([]);
  const [requirementInput, setRequirementInput] = useState('');
  const [responsabilitys, setResponsability] = useState([]);
  const [responsabilityInput, setResponsibilityInput] = useState('');
  const [sucess, setSucess] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [hourWeek, setHourWeek] = useState('');

  function submit(event) {
    const sizeReq = Object.keys(requiriments).length;
    const sizeRes = Object.keys(responsabilitys).length;

    event.preventDefault();
    fetch(` ${process.env.REACT_APP_API}/vacancy`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title,
        description,
        hourWeek,
        responsabilitys,
        requiriments,
      }),
    })
      .then((res) =>
        res.json().then((data) => {
          setSucess(true);

          if (sizeReq < 2) {
            setSucess(false);
            alert(
              'ATENÇÃO !! É Necessario no minimo 2 Requisitos e Qualificações para cadastrar Vaga',
            );
          }
          if (sizeRes < 2) {
            setSucess(false);
            alert(
              'ATENÇÃO !! É Necessario no minimo 2 Responsabilidades e atribuições para cadastrar Vaga',
            );
          }
        }),
      )

      .catch((err) => {});
  }

  return (
    <>
      {sucess === true ? (
        <SuccessRegistration />
      ) : (
        <section>
          <main id="main">
            <div className="boxContainer">
              <form id="formIntern" onSubmit={submit}>
                <div className="positionSeparator ">
                  <div className="containerTitleRegistration gapTitlebox">
                    <h1>Cadastro de Vagas</h1>
                    <div className="separatorBar"></div>
                  </div>
                </div>

                <div className="styleFormsRegistration">
                  <div className="forms">
                    <fieldset>
                      <label htmlFor="vaga">Titulo da vaga</label>
                      <input
                        required
                        type="text"
                        id="vaga"
                        name="vaga"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Titulo vaga"
                      />
                    </fieldset>

                    <fieldset>
                      <label htmlFor="descricao">Descrição da vaga</label>
                      <input
                        required
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        id="descricao"
                        name="descricao"
                        placeholder="Descrição da vaga"
                      />
                    </fieldset>

                    <fieldset className="atributtesStyles">
                      <label htmlFor="horas">
                        <p>Horas</p>
                      </label>
                      <div className="containerHourWeek">
                        <input
                          required
                          type="text"
                          value={hourWeek}
                          onChange={(e) => setHourWeek(e.target.value)}
                          id="horas"
                          name="horas"
                          placeholder="horas"
                          className="fieldAttributes"
                        />
                        Semanais
                      </div>
                    </fieldset>

                    <label className="Positionlabel">
                      Responsabilidades e Atribuiçoes
                    </label>
                    <div className="positionAboutInformation">
                      <input
                        type="text"
                        id="responsibility"
                        name="horas"
                        value={responsabilityInput}
                        placeholder="Descreva os requisitos"
                        onChange={(value) => {
                          setResponsibilityInput(value.target.value);
                        }}
                      />
                      <button
                        type="button"
                        className="buttonAdd"
                        onClick={(e) => {
                          e.preventDefault();
                          if (responsabilityInput !== '') {
                            setResponsability([
                              ...responsabilitys,
                              responsabilityInput,
                            ]);
                          }
                          setResponsibilityInput('');
                        }}
                      >
                        <FaPlus className="size" />
                      </button>
                    </div>

                    <div className="position-atribuicoes">
                      <div className="positionContentTodoList">
                        <ul>
                          {responsabilitys.map((responsability, index) => {
                            return (
                              <li className="order" key={responsability}>
                                {responsability}
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const resultone = responsabilitys.filter(
                                      (responsability, indexFilter) => {
                                        return (
                                          indexFilter !== index ??
                                          responsability
                                        );
                                      },
                                    );
                                    setResponsability(resultone);
                                  }}
                                >
                                  <FaTrash className="trashIcon" />
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>

                    <label className="Positionlabel">
                      Requisitos e Qualificações
                    </label>
                    <div className="positionAboutInformation">
                      <input
                        type="text"
                        id="requirement"
                        value={requirementInput}
                        name="horas"
                        placeholder="Descreva os requisitos"
                        onChange={(value) => {
                          setRequirementInput(value.target.value);
                        }}
                      />

                      <button
                        className="buttonAdd"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          if (requirementInput !== '') {
                            setrequiriments([
                              ...requiriments,
                              requirementInput,
                            ]);
                          }
                          setRequirementInput('');
                        }}
                      >
                        <FaPlus className="size" />
                      </button>
                    </div>

                    <div>
                      <div className="positionContentTodoList">
                        <ul>
                          {requiriments.map((requirement, index) => {
                            return (
                              <li className="order">
                                {requirement}
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const result = requiriments.filter(
                                      (requirement, indexFilter) => {
                                        return (
                                          indexFilter !== index ?? requirement
                                        );
                                      },
                                    );
                                    setrequiriments(result);
                                  }}
                                >
                                  <FaTrash className="trashIcon" />
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <button className="buttonRegistration" type="submit">
                    Cadastrar Vaga
                  </button>
                </div>
              </form>
            </div>
          </main>
        </section>
      )}
    </>
  );
}

export default Registration;
