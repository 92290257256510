import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import Loanding from 'Pages/Home/Components/LoadingComponent';
import Alerts from 'Pages/Home/Components/Alerts';
import userProfileIcon from 'Assets/userProfileIcon.svg';
import { getSessionToken } from 'utils/sessionLocalStore';
import { FcSettings } from 'react-icons/fc';
import 'Pages/Home/Components/Perfil/styles.css';

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');
myHeaders.append('Authorization', `Bearer ${getSessionToken()}`);

function Perfil() {
  const [isSucessRegister, setIsSucessRegister] = useState('');
  const [loandig, setLoandig] = useState(true);
  const [adminInfo, setAdminInfo] = useState({});
  const [sucess, setSucess] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  function handleFormAdm(event) {
    event.preventDefault();

    fetch(` ${process.env.REACT_APP_API}/admin`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ name, email, senha }),
    }).then((res) =>
      res.json().then((data) => {
        console.log(data);
        console.log('Cadastrado com sucesso!!');
        setIsSucessRegister(data);
        setSucess(true);
      }),
    );
  }

  useEffect(() => {
    setLoandig(true);

    fetch(`${process.env.REACT_APP_API}/me`, {
      headers: myHeaders,
    }).then((res) =>
      res.json().then((data) => {
        setLoandig(false);
        setAdminInfo(data);
      }),
    );
  }, []);

  return (
    <>
      <section className="admUsers">
        <div className="modelBox">
          {loandig ? (
            <Loanding />
          ) : (
            <>
              <div className="iconUser">
                <img src={userProfileIcon} alt="" />
              </div>
              <div className="userInfoBox">
                <span className="positionInfo">
                  <p className="textInfoTitle">Nome:</p>
                  <p className="infoUser">{adminInfo.name}</p>
                </span>

                <span className="positionInfo">
                  <p className="textInfoTitle">E-mail:</p>
                  <p className="infoUser">{adminInfo.email}</p>
                </span>
                <span className="changePassword">
                  <FcSettings />
                  <a href="#">Alterar minha senha</a>
                </span>
              </div>
            </>
          )}
        </div>

        <div className="boxPerfil">
          <form onSubmit={handleFormAdm}>
            {sucess === true ? (
              <Alerts Data={isSucessRegister} />
            ) : (
              <>
                <div className="containerTitle">
                  <h3 className="textSizeView smallTxt">Criar novo Usúario</h3>
                  <div className="separatorBar"></div>
                </div>
                <div className="styleLabelLoguin ">
                  <label>
                    <p className="red">*</p> Nome
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <label>
                    <p className="red">*</p>E-mail
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="posLabel"
                    required
                  />

                  <label>
                    <p className="red">*</p>Senha
                  </label>
                  <input
                    type="password"
                    id="senha"
                    name="senha"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                    className="posLabel"
                    required
                  />

                  <button type="submit" className="buttonUserInfo">
                    Criar Usuario
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </section>
    </>
  );
}

export default Perfil;
