import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import 'Pages/Home/Components/FormDialog/styles.css';

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

export default function FormDialog({
  data,
  setOpen,
  open,
  onSave,
  item,
  onDelete,
}) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [hourWeek, setHourWeek] = useState('');
  const [requiriments, setRequiriments] = useState([]);
  const [responsabilitys, setResponsabilitys] = useState([]);

  function handleDelete() {
    fetch(`${process.env.REACT_APP_API}/vacancie/${data.id}`, {
      method: 'PUT',
    }).then((res) =>
      res.json().then((responseData) => {
        onDelete(responseData);
        setOpen(false);
      }),
    );
  }

  function handleSave(event) {
    event.preventDefault();
    fetch(`${process.env.REACT_APP_API}/edit/${data.id}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        title,
        description,
        hourWeek,
        requiriments: requiriments,
        responsabilitys: responsabilitys,
      }),
    }).then((res) =>
      res.json().then((responseData) => {
        onSave(responseData);
        setOpen(false);
      }),
    );
  }

  useEffect(() => {
    if (open) {
      setTitle(data.title);
      setDescription(data.description);
      setHourWeek(data.hourWeek);
      setRequiriments(data.requiriments);
      setResponsabilitys(data.responsabilitys);
    }
  }, [data, open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleChangeResponsabilitys(index, novoValor) {
    let tmp = [...responsabilitys];
    tmp[index] = novoValor;
    setResponsabilitys(tmp);
  }

  function handleChangerequiriments(index, novoValor) {
    let tmp = [...requiriments];
    tmp[index] = novoValor;
    setRequiriments(tmp);
  }

  return (
    <Dialog open={open} onClose={handleClickOpen}>
      <DialogTitle>Editar</DialogTitle>
      <DialogContent>
        <h4 id="txtDialog">Título Vaga </h4>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          type="text"
          fullWidth
          variant="standard"
        />
        <h4 id="txtDialog">Descrição da Vaga</h4>
        <TextField
          autoFocus
          margin="dense"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          type="text"
          fullWidth
          variant="standard"
        />
        <h4 id="txtDialog">horas por semana</h4>
        <TextField
          autoFocus
          margin="dense"
          id="hourWeek"
          value={hourWeek}
          onChange={(e) => setHourWeek(e.target.value)}
          type="text"
          fullWidth
          variant="standard"
        />
        <h4 id="txtDialog">
          Responsabilidades
          <small>(É obrigatorio cadastrar no mínimo 2 rensposabilidades)</small>
        </h4>

        <TextField
          autoFocus
          margin="dense"
          name="responsabilitys1"
          id="responsabilitys1"
          value={responsabilitys[0]}
          onChange={(e) => handleChangeResponsabilitys(0, e.target.value)}
          label="Responsabilidades 1"
          type="text"
          fullWidth
          variant="standard"
        />
        <TextField
          autoFocus
          margin="dense"
          name="responsabilitys2"
          id="responsabilitys2"
          value={responsabilitys[1]}
          onChange={(e) => handleChangeResponsabilitys(1, e.target.value)}
          label="Responsabilidades 2"
          type="text"
          fullWidth
          variant="standard"
        />
        <TextField
          autoFocus
          margin="dense"
          name="responsabilitys3"
          id="responsabilitys3"
          value={responsabilitys[2]}
          onChange={(e) => handleChangeResponsabilitys(2, e.target.value)}
          label="Responsabilidades 3"
          type="text"
          fullWidth
          variant="standard"
        />
        <h4 id="txtDialog">
          Requisitos
          <small> (É obrigatorio cadastrar no mínimo 2 requisitos)</small>
        </h4>
        <TextField
          autoFocus
          margin="dense"
          name="responsabilitys1"
          id="requiriments1"
          value={requiriments[0]}
          onChange={(e) => handleChangerequiriments(0, e.target.value)}
          label="Requisitos 1"
          type="text"
          fullWidth
          variant="standard"
        />
        <TextField
          autoFocus
          margin="dense"
          name="responsabilitys2"
          id="requiriments2"
          value={requiriments[1]}
          onChange={(e) => handleChangerequiriments(1, e.target.value)}
          label="Requisitos 2"
          type="text"
          fullWidth
          variant="standard"
        />
        <TextField
          autoFocus
          margin="dense"
          name="responsabilitys3"
          id="requiriments3"
          value={requiriments[2]}
          onChange={(e) => handleChangerequiriments(2, e.target.value)}
          label="Requisitos 3"
          type="text"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={handleDelete}>Excluir</Button>
        <Button onClick={handleSave}>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
}
